export  type Endpoints = { [queryId: string]: string };
export  type Client = {
  brands: Brand[];
  domain: string;
  id: string;
  name: string;
  properties: {
    active: boolean;
    emailMetrics: boolean;
    smsMetrics: boolean;
    vmMetrics: boolean;
  };
  reference: string;
};

export type Brand = {
  id: string;
  name: string;
  properties: {
    active: boolean;
    emailMetrics: boolean;
    smsMetrics: boolean;
    vmMetrics: boolean;
  };
  reference: string;
  internalDisplayName: string;
};

export const endpoints: Endpoints = {
  emailClickRate: "emailClickRate",
  communicationFunnelCurrentMonth: "communicationFunnelCurrentMonth",
  communicationFunnelPreviousMonth: "communicationFunnelPreviousMonth",
  accountEmailFunnelCurrentMonth: "accountEmailFunnelCurrentMonth",
  accountEmailFunnelPreviousMonth: "accountEmailFunnelPreviousMonth",
  emailOpenRate: "emailOpenRate",
  smsOptOutRate: "smsOptOutRate",
  activeAccountMonthlyTrend: "activeAccountMonthlyTrend",
  activeAccountsByMonth: "activeAccountsByMonth",
  commsClickRate: "commsClickRate",
  commsOpenRate: "commsOpenRate",
  channelDistribution: "channelDistribution",
  channelDistributionMTD: "channelDistributionMTD",
  commsClickRateMTD: "commsClickRateMTD",
  commsOpenRateMTD: "commsOpenRateMTD",
  communicationRateMTD: "communicationRateMTD",
  communicationRatePreviousMonth: "communicationRatePreviousMonth",
  paymentTrends: "paymentTrends",
  accountsByDelinquencyBucket: "accountsByDelinquencyBucket",
  accountsByDelinquencyBucketMTD: "accountsByDelinquencyBucketMTD",
  emailEngagementMonthlyTotal: "emailEngagementMonthlyTotal",
  emailClickRateMTD: "emailClickRateMTD",
  emailOpenRateMTD: "emailOpenRateMTD",
  emailUnsubscribeRate: "emailUnsubscribeRate",
  maxEmailsSentToAccount: "maxEmailsSentToAccount",
  monthlyEmailCadence: "monthlyEmailCadence",
  monthlyPaymentTrends: "monthlyPaymentTrends",
  paymentAmountsByMonth: "paymentAmountsByMonth",
  paymentCountsByMonth: "paymentCountsByMonth",
  paymentRate: "paymentRate",
  paymentRateMTD: "paymentRateMTD",
  placementsAndRetractions: "placementsAndRetractions",
  placementsMTD: "placementsMTD",
  sendRate: "sendRate",
  sendRatePreviousMonth: "sendRatePreviousMonth",
  smsClickRate: "smsClickRate",
  smsClickRateMTD: "smsClickRateMTD",
  smsDeliveryRate: "smsDeliveryRate",
  smsDeliveryRateMTD: "smsDeliveryRateMTD",
  uniqueAccountClickRateMTD: "uniqueAccountClickRateMTD",
  uniqueAccountSendsAndClick: "uniqueAccountSendsAndClick",
  vmDeliveryRate: "vmDeliveryRate",
  vmDeliveryRateMTD: "vmDeliveryRateMTD",
  vmOptOutRate: "vmOptOutRate",
};

export const clients: Client[] = [];
